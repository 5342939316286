import React from "react"
import { graphql } from "gatsby"
import Link from "gatsby-link"
import Footer from "../components/Footer"
import SEO from "../components/seo"
import Members from "../components/Members"
import Header from "../components/Header"
import PageWrapper from "../PageWrapper"
import PAGEDATA from "../../content/team.json"

//                    { data, location }
const CompanyIndex = ({ data }) => {

  const siteTitle = data.site.siteMetadata.title

  return (
    <PageWrapper>
      <Header
        component={
          <div className="d-lg-flex align-items-center pb-5 pb-sm-0">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="header-hero-content">
                    {/* <!--======TEAM PART START======--> */}
                    <div className="row justify-content-center pt-110">
                      <div className="col-lg-5 col-md-8 col-sm-9">
                        <div className="section-title text-center pb-20">
                          {/* <h4 className="title">Our Team</h4> */}
                        </div>
                        {/* <!-- section title --> */}
                      </div>
                    </div>
                    {/* <!-- row --> */}
                    {/* <Members data={PAGEDATA.members} /> */}

                    {/* <!--======TEAM PART END======--> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        title={siteTitle}
      />
      <SEO title="About lexiQA - Automated QA Tool" />
      <SEO description="Meet the team of engineers and language experts behind our high-tech quality assurance solution" />

      {/* <!--====== STORY SECTION START======--> */}

      <section className="pt-100 pb-100">
        <div className="container">
          <Members data={PAGEDATA.members} />
        </div>
      </section>

      <section className="about-area pt-100 bg-light pb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="about-title text-center">
                <h1 className="company--welcome">THE STORY</h1>
                <h3 className="company--title mt-20">
                  <p>
                    The conceptual seeds of lexiQA were sown back in 2013 when
                    Yannis realised that there is a gap in the localization
                    industry. After various iterations together with the other
                    two co-founders, the idea started taking solid form in 2015.
                    Eventually lexiQA was incorporated as a company in the UK in
                    January 2016, having secured an initial round of direct
                    investment from Pi Campus and LVenture Group in Rome, Italy.
                    Since then it has been steadily growing and developing its
                    innovative solution for the global market.
                  </p>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!--======STORY SECTION END======--> */}

      {/* <!--====== CALL TO ACTION START======--> */}
      <section className="call-to-action-area bg_cover bg-white pt-115 pb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="call-action">
                <div className="section-title text-center section-title-white">
                  <h5 className="title mb-3">
                    <span>Get In Touch</span>
                  </h5>
                  <h6 className="sub-title mb-5">
                    Interested in working with us? Drop us a line to find out
                    how!
                  </h6>
                </div>
              </div>
              <div className="text-center">
                <Link className="main-btn btn-primary" to={"/contact/"}>
                  CONTACT US
                </Link>
              </div>
            </div>
          </div>
          {/* <!-- row --> */}
        </div>
        {/* <!-- container --> */}
      </section>
      {/* <!--====== CALL TO ACTION END======--> */}

      <Footer />
    </PageWrapper>
  )
}

export default CompanyIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
