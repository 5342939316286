import React from "react"
import "./Members.scss"

function Members(props) {
  return (    
    <div className="members-component row justify-content-center">
      {props.data.map(member => {
        return (
          <div key={member.title} className="member-col col-lg-4 col-md-6 col-sm-9 mb-30">
            <div className="single-team mt-30">
              <div className="team-image">
                <img src={"/img/" + member.img} alt={member.alt} />                
              </div>
              <div className="team-content text-center">
                <h4 className="members--title team-title">
                  {member.title}
                </h4>
                <span className="sub-title">{member.subtitle}</span>
               
                <hr />
                <span className="text">{member.text}</span>
              </div>
            </div>            
          </div>
        )
      })}
    </div>
  )
}

export default Members
